(function ($) {
  Drupal.behaviors.productBenefitsV1 = {
    /**
     * Display the product benefits by reading the product attributes, parsing them, then returning
     * the appropriate variables from the translations.
     * Hello.
     * Bye
     */
    attach: function (context) {
      var self = this;

      self.init(context);
      $(document).on('quickshop.loaded', function (event) {
        self.init(context);
      });
    },

    init: function (context) {
      var $module = $('.js-product-benefits-v1:not(.attached)', context);

      $module.each(function () {
        var $me = $(this);

        $me.addClass('attached');

        var $results = $('.js-benefits', $me);
        var tags = $(this).data('benefits');

        // Split continuous strings and commas - https://gist.github.com/fcamel/1081892
        tags = $.map(tags.split(','), $.trim);

        _.each(tags, function (tag) {
          if (!_.isUndefined(site.translations.product_benefits[tag])) {
            var rendered = site.template.get({
              name: 'product_benefits_item',
              data: site.translations.product_benefits[tag]
            });

            $results.append(rendered);
          }
        });

        // If we have tags, show the module
        if (tags.length) {
          $me.removeClass('hidden');
        }
      });

      $(document).trigger('benefits.loaded');
    }
  };
})(jQuery);
